/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";

import * as action from "./action";

export const getListState = (state) => state.taskDocumentApp.list;
export const getListStateTask = (state) => state.taskApp.addEditRequiredDocument;

const loadMasterTaskDocumentListAsync = async (query) => GET(`/required_document?${query}`);

const addMasterTaskDocumentAsync = async (item) => POST(`/required_document`, item);
const editMasterTaskDocumentAsync = async (id, item) => PUT(`/required_document/${id}`, item);
const deleteMasterTaskDocumentAsync = async (id) => DELETE(`/required_document/${id}`);

function* loadMasterTaskDocumentList({ payload }) {
  try {
    const list = yield select(getListState);
    const listTask = yield select(getListStateTask);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
      {
        name: "task_id",
        value: listTask.data.id,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterTaskDocumentListAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterTaskDocumentListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterTaskDocumentListError(error));
  }
}

function* saveAddeditMasterTaskDocument({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterTaskDocumentAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterTaskDocumentAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterTaskDocumentSuccess(response));
      yield put(action.loadMasterTaskDocumentList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterTaskDocumentError(error));
  }
}

function* deleteMasterTaskDocument({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterTaskDocumentAsync, payload.item.id);
        yield put(action.deleteMasterTaskDocumentSuccess());
        yield put(action.loadMasterTaskDocumentList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterTaskDocumentError(error));
  }
}

export function* watchLoadMasterTaskDocumentList() {
  yield takeEvery(action.LOAD_MASTER_TASK_DOCUMNET_LIST, loadMasterTaskDocumentList);
}

export function* watchSaveAddeditMasterTaskDocument() {
  yield takeEvery(action.SAVE_ADDEDIT_MASTER_TASK_DOCUMENT, saveAddeditMasterTaskDocument);
}

export function* watchDeleteMasterTaskDocument() {
  yield takeEvery(action.DELETE_MASTER_TASK_DOCUMENT, deleteMasterTaskDocument);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterTaskDocumentList),
    fork(watchSaveAddeditMasterTaskDocument),
    fork(watchDeleteMasterTaskDocument),
  ]);
}
