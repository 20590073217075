import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_TASK_DOCUMNET_LIST = "LOAD_MASTER_TASK_DOCUMNET_LIST";
export const LOAD_MASTER_TASK_DOCUMNET_LIST_SUCCESS =
  "LOAD_MASTER_TASK_DOCUMNET_LIST_SUCCESS";
export const LOAD_MASTER_TASK_DOCUMNET_LIST_ERROR =
  "LOAD_MASTER_TASK_DOCUMNET_LIST_ERROR";

export const ADDEDIT_MASTER_TASK_DOCUMENT = "ADDEDIT_MASTER_TASK_DOCUMENT";
export const SAVE_ADDEDIT_MASTER_TASK_DOCUMENT =
  "SAVE_ADDEDIT_MASTER_TASK_DOCUMENT";
export const SAVE_ADDEDIT_MASTER_TASK_DOCUMENT_SUCCESS =
  "SAVE_ADDEDIT_MASTER_TASK_DOCUMENT_SUCCESS";
export const SAVE_ADDEDIT_MASTER_TASK_DOCUMENT_ERROR =
  "SAVE_ADDEDIT_MASTER_TASK_DOCUMENT_ERROR";
export const CLOSE_ADDEDIT_MASTER_TASK_DOCUMENT =
  "CLOSE_ADDEDIT_MASTER_TASK_DOCUMENT";

export const DELETE_MASTER_TASK_DOCUMENT = "DELETE_MASTER_TASK_DOCUMENT";
export const DELETE_MASTER_TASK_DOCUMENT_SUCCESS =
  "DELETE_MASTER_TASK_DOCUMENT_SUCCESS";
export const DELETE_MASTER_TASK_DOCUMENT_ERROR =
  "DELETE_MASTER_TASK_DOCUMENT_ERROR";

export const loadMasterTaskDocumentList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "index",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_TASK_DOCUMNET_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
      taskId: param.taskId,
    },
  };
};

export const loadMasterTaskDocumentListSuccess = (data) => ({
  type: LOAD_MASTER_TASK_DOCUMNET_LIST_SUCCESS,
  payload: data,
});

export const loadMasterTaskDocumentListError = (error) => ({
  type: LOAD_MASTER_TASK_DOCUMNET_LIST_ERROR,
  payload: error,
});

export const addeditMasterTaskDocument = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_TASK_DOCUMENT,
  payload: {
    item,
    mode,
  },
});

export const closeAddeditMasterTaskDocument = () => ({
  type: CLOSE_ADDEDIT_MASTER_TASK_DOCUMENT,
});

export const saveAddeditMasterTaskDocument = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_TASK_DOCUMENT,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterTaskDocumentSuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_TASK_DOCUMENT_SUCCESS,
  payload: data,
});

export const saveAddeditMasterTaskDocumentError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_TASK_DOCUMENT_ERROR,
  payload: error,
});

export const deleteMasterTaskDocument = (data, confirmed = false) => ({
  type: DELETE_MASTER_TASK_DOCUMENT,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterTaskDocumentSuccess = () => ({
  type: DELETE_MASTER_TASK_DOCUMENT_SUCCESS,
});

export const deleteMasterTaskDocumentError = (error) => ({
  type: DELETE_MASTER_TASK_DOCUMENT_ERROR,
  payload: error,
});
