import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_CITY_LIST = "LOAD_MASTER_CITY_LIST";
export const LOAD_MASTER_CITY_LIST_SUCCESS = "LOAD_MASTER_CITY_LIST_SUCCESS";
export const LOAD_MASTER_CITY_LIST_ERROR = "LOAD_MASTER_CITY_LIST_ERROR";

export const ADDEDIT_MASTER_CITY = "ADDEDIT_MASTER_CITY";
export const ADDEDIT_MASTER_CITY_SUCCESS = "ADDEDIT_MASTER_CITY_SUCCESS";
export const ADDEDIT_MASTER_CITY_ERROR = "ADDEDIT_MASTER_CITY_ERROR";
export const SAVE_ADDEDIT_MASTER_CITY = "SAVE_ADDEDIT_MASTER_CITY";
export const SAVE_ADDEDIT_MASTER_CITY_SUCCESS =
  "SAVE_ADDEDIT_MASTER_CITY_SUCCESS";
export const SAVE_ADDEDIT_MASTER_CITY_ERROR = "SAVE_ADDEDIT_MASTER_CITY_ERROR";
export const CLOSE_ADDEDIT_MASTER_CITY = "CLOSE_ADDEDIT_MASTER_CITY";

export const DELETE_MASTER_CITY = "DELETE_MASTER_CITY";
export const DELETE_MASTER_CITY_SUCCESS = "DELETE_MASTER_CITY_SUCCESS";
export const DELETE_MASTER_CITY_ERROR = "DELETE_MASTER_CITY_ERROR";

// BIND DATA LIST PROVINCE
export const GET_MASTER_PROVINCE_LIST = "GET_MASTER_PROVINCE_LIST";
export const GET_MASTER_PROVINCE_LIST_SUCCESS =
  "GET_MASTER_PROVINCE_LIST_SUCCESS";
export const GET_MASTER_PROVINCE_LIST_ERROR = "GET_MASTER_PROVINCE_LIST_ERROR";

export const loadMasterCityList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_CITY_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterCityListSuccess = (data) => ({
  type: LOAD_MASTER_CITY_LIST_SUCCESS,
  payload: data,
});

export const loadMasterCityListError = (error) => ({
  type: LOAD_MASTER_CITY_LIST_ERROR,
  payload: error,
});

export const addeditMasterCity = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_CITY,
  payload: {
    item,
    mode,
  },
});

export const addEditMasterCitySuccess = (data) => ({
  type: ADDEDIT_MASTER_CITY_SUCCESS,
  payload: data,
});

export const addEditMasterCityError = (error) => ({
  type: ADDEDIT_MASTER_CITY_ERROR,
  payload: error,
});

export const closeAddeditMasterCity = () => ({
  type: CLOSE_ADDEDIT_MASTER_CITY,
});

export const saveAddeditMasterCity = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_CITY,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterCitySuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_CITY_SUCCESS,
  payload: data,
});

export const saveAddeditMasterCityError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_CITY_ERROR,
  payload: error,
});

export const deleteMasterCity = (data, confirmed = false) => ({
  type: DELETE_MASTER_CITY,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterCitySuccess = () => ({
  type: DELETE_MASTER_CITY_SUCCESS,
});

export const deleteMasterCityError = (error) => ({
  type: DELETE_MASTER_CITY_ERROR,
  payload: error,
});

export const getProvinceList = () => ({
    type: GET_MASTER_PROVINCE_LIST,
  });
  
  export const getProvinceListSuccess = (data) => ({
    type: GET_MASTER_PROVINCE_LIST_SUCCESS,
    payload: data,
  });
  
  export const getProvinceListError = (error) => ({
    type: GET_MASTER_PROVINCE_LIST_ERROR,
    payload: error,
  });
