import { INIT_PAGE_STATE } from "../../../constants/constants";

import {
  ADDEDIT_MASTER_COMPANY,
  CLOSE_ADDEDIT_MASTER_COMPANY,
  LOAD_MASTER_COMPANY_LIST,
  LOAD_MASTER_COMPANY_LIST_ERROR,
  LOAD_MASTER_COMPANY_LIST_SUCCESS,
  DELETE_MASTER_COMPANY,
  DELETE_MASTER_COMPANY_ERROR,
  DELETE_MASTER_COMPANY_SUCCESS,
  SAVE_ADDEDIT_MASTER_COMPANY,
  SAVE_ADDEDIT_MASTER_COMPANY_ERROR,
  SAVE_ADDEDIT_MASTER_COMPANY_SUCCESS,
  ADDEDIT_MASTER_COMPANY_SUCCESS,
  ADDEDIT_MASTER_COMPANY_ERROR,
  GET_MASTER_PROVINCE_LIST,
  GET_MASTER_PROVINCE_LIST_SUCCESS,
  GET_MASTER_PROVINCE_LIST_ERROR,
  GET_MASTER_CITY_LIST,
  GET_MASTER_CITY_LIST_SUCCESS,
  GET_MASTER_CITY_LIST_ERROR,
  GET_MASTER_COUNTRY_LIST,
  GET_MASTER_COUNTRY_LIST_ERROR,
  GET_MASTER_COUNTRY_LIST_SUCCESS,
} from "./action";

const INIT_STATE = {
  list: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    orderBy: null,
    sortedBy: "asc",
    filterFields: "name:ilike;mProvince.name:ilike;zipcode:ilike",
    searchJoin: "or",
    page: INIT_PAGE_STATE,
    message: null,
  },
  addEdit: {
    data: null,
    mode: "add",
    loading: false,
    error: false,
    message: null,
  },
  deleting: {
    data: null,
    loading: false,
    error: false,
    message: null,
    confirmed: false,
  },
  listCity: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listProvince: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listCountry: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_MASTER_COMPANY_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: true,
          error: false,
          filter: action.payload.filter,
          orderBy: action.payload.orderBy,
          sortedBy: action.payload.sortedBy,
          page: INIT_PAGE_STATE,
          message: null,
        },
      };

    case LOAD_MASTER_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          page: {
            ...state.list.page,
            currentPage: action.payload.meta.pagination.current_page,
            selectedPageSize: action.payload.meta.pagination.per_page,
            totalItemCount: action.payload.meta.pagination.total,
            totalPage: action.payload.meta.pagination.total_pages,
            itemCount: action.payload.meta.pagination.count,
          },
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_MASTER_COMPANY_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case ADDEDIT_MASTER_COMPANY:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          mode: action.payload.mode,
          loading: false,
          error: false,
          message: null,
          provinces: null,
        },
      };

    case ADDEDIT_MASTER_COMPANY_SUCCESS:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          provinces: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case ADDEDIT_MASTER_COMPANY_ERROR:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          provinces: null,
          loading: false,
          error: true,
          message: action.payload,
        },
      };

    case SAVE_ADDEDIT_MASTER_COMPANY:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          loading: true,
          error: false,
          message: null,
        },
      };

    case SAVE_ADDEDIT_MASTER_COMPANY_SUCCESS:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          loading: false,
          error: true,
        },
      };

    case SAVE_ADDEDIT_MASTER_COMPANY_ERROR:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          loading: false,
          error: true,
          message: action.payload.toString(),
        },
      };

    case CLOSE_ADDEDIT_MASTER_COMPANY:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          mode: "add",
          loading: false,
          error: false,
          message: null,
        },
      };

    case DELETE_MASTER_COMPANY:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: action.payload.item,
          confirmed: action.payload.confirmed,
          loading: action.payload.confirmed,
          error: false,
          message: null,
        },
      };

    case DELETE_MASTER_COMPANY_SUCCESS:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: null,
          loading: false,
          error: false,
          message: null,
          confirmed: false,
        },
      };

    case DELETE_MASTER_COMPANY_ERROR:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          loading: false,
          error: true,
          message: action.payload,
          confirmed: false,
        },
      };

    case GET_MASTER_CITY_LIST:
      return {
        ...state,
        listCity: {
          ...state.listCity,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_CITY_LIST_SUCCESS:
      return {
        ...state,
        listCity: {
          ...state.listCity,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_CITY_LIST_ERROR:
      return {
        ...state,
        listCity: {
          ...state.listCity,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case GET_MASTER_PROVINCE_LIST:
      return {
        ...state,
        listProvince: {
          ...state.listProvince,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_PROVINCE_LIST_SUCCESS:
      return {
        ...state,
        listProvince: {
          ...state.listProvince,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_PROVINCE_LIST_ERROR:
      return {
        ...state,
        listProvince: {
          ...state.listProvince,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case GET_MASTER_COUNTRY_LIST:
      return {
        ...state,
        listCountry: {
          ...state.listCountry,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        listCountry: {
          ...state.listCountry,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_COUNTRY_LIST_ERROR:
      return {
        ...state,
        listCountry: {
          ...state.listCountry,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };
    default:
      return state;
  }
};
