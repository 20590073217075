import { INIT_PAGE_STATE } from "../../../constants/constants";

import {
  LOAD_STAT_TASK_LIST,
  LOAD_STAT_TASK_LIST_ERROR,
  LOAD_STAT_TASK_LIST_SUCCESS,
  LOAD_CLIENT_NEED_ATT,
  LOAD_CLIENT_NEED_ATT_ERROR,
  LOAD_CLIENT_NEED_ATT_SUCCESS,
  LOAD_STAT_NEED_ATT,
  LOAD_STAT_NEED_ATT_ERROR,
  LOAD_STAT_NEED_ATT_SUCCESS,
} from "./action";

const INIT_STATE = {
  statTask: null,
  statNeedAtt: null,
  needAtt: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    filterFields: "id:ilike;name:ilike",
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_STAT_TASK_LIST:
      return {
        ...state,
        statTask: null
      };

    case LOAD_STAT_TASK_LIST_SUCCESS:
      return {
        ...state,
        statTask: action.payload.data
      };

    case LOAD_STAT_TASK_LIST_ERROR:
      return {
        ...state,
        statTask: null
      };


    case LOAD_STAT_NEED_ATT:
      return {
        ...state,
        statNeedAtt: null
      };

    case LOAD_STAT_NEED_ATT_SUCCESS:
      return {
        ...state,
        statNeedAtt: action.payload
      };

    case LOAD_STAT_NEED_ATT_ERROR:
      return {
        ...state,
        statNeedAtt: null
      };

    case LOAD_CLIENT_NEED_ATT:
      return {
        ...state,
        needAtt: {
          ...state.needAtt,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case LOAD_CLIENT_NEED_ATT_SUCCESS:
      return {
        ...state,
        needAtt: {
          ...state.needAtt,
          data: action.payload,
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_CLIENT_NEED_ATT_ERROR:
      return {
        ...state,
        needAtt: {
          ...state.needAtt,
          data: null,
          loading: false,
          error: true,
          message: action.payload,
        },
      };

    default:
      return state;
  }
};
