import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { GET } from "../../helpers/apiHelper";
import * as action from "./action";

export const getListState = (state) => state.arsipApp.listClient;

const loadMasterCompanyListArsipAsync = async () => GET(`/company`);
const loadClientListArsipAsync = async (query) =>
  GET(`/client/get-company?${query}`);
const loadCheckArsipAsync = async (client_id) =>
  GET(`/berkas/check?client_id=${client_id}`);
const loadBerkasClientListAsync = async (client_id, name) =>
  GET(`berkas/get-berkas?client_id=${client_id}&name=${name}`);
const loadDocumentBerkasClientListAsync = async (
  client_id,
  name,
  docTypeName
) =>
  GET(
    `berkas/get-docs?client_id=${client_id}&name=${name}&docTypeName=${docTypeName}`
  );

function* loadMasterCompanyArsipList() {
  try {
    const response = yield call(loadMasterCompanyListArsipAsync);
    yield put(action.getCompanyListSuccess(response));
  } catch (error) {
    yield put(action.getCompanyListError(error));
  }
}

function* loadClientArsipList({ payload }) {
  const list = yield select(getListState);

  try {
    let param = [
      {
        name: "company_id",
        value: payload.id || payload.idCompany,
      },
      {
        name: "year",
        value: payload.year,
      },
    ];

    if (list.search != null) {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.search,
          },
        ],
      ];
    }

    const response = yield call(
      loadClientListArsipAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.getClientListSuccess(response));
  } catch (error) {
    yield put(action.getClientListError(error));
  }
}

function* loadCheckArsipList({ payload }) {
  try {
    let response = null;
    response = yield call(loadCheckArsipAsync, payload.client_id);
    yield put(action.checkBerkasListSuccess(response));
  } catch (error) {
    yield put(action.checkBerkasListError(error));
  }
}

function* getListBerkasClient({ payload }) {
  try {
    let response = null;
    response = yield call(
      loadBerkasClientListAsync,
      payload.client_id,
      payload.name
    );
    yield put(action.getBerkasClientListSuccess(response));
  } catch (error) {
    yield put(action.getBerkasClientListError(error));
  }
}

function* getDocumentListBerkasClient({ payload }) {
  try {
    let response = null;
    response = yield call(
      loadDocumentBerkasClientListAsync,
      payload.client_id,
      payload.name,
      payload.docTypeName
    );
    yield put(action.getDocumentBerkasClientListSuccess(response));
  } catch (error) {
    yield put(action.getDocumentBerkasClientListError(error));
  }
}

export function* watchLoadMasterCompanyArsipList() {
  yield takeEvery(action.GET_MASTER_COMPANY_LIST, loadMasterCompanyArsipList);
}

export function* watchLoadClientArsipList() {
  yield takeEvery(action.GET_CLIENT_LIST, loadClientArsipList);
}

export function* watchLoadCheckBerkasList() {
  yield takeEvery(action.CHECK_BERKAS, loadCheckArsipList);
}

export function* watchGetListBerkasClient() {
  yield takeEvery(action.GET_BERKAS_CLIENT_LIST, getListBerkasClient);
}

export function* watchGetDocumentListBerkasClient() {
  yield takeEvery(
    action.GET_DOCUMENTS_BERKAS_CLIENT_LIST,
    getDocumentListBerkasClient
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterCompanyArsipList),
    fork(watchLoadClientArsipList),
    fork(watchLoadCheckBerkasList),
    fork(watchGetListBerkasClient),
    fork(watchGetDocumentListBerkasClient),
  ]);
}
