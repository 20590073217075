import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_JOB_DESCRIPTION_LIST = "LOAD_MASTER_JOB_DESCRIPTION_LIST";
export const LOAD_MASTER_JOB_DESCRIPTION_LIST_SUCCESS =
  "LOAD_MASTER_JOB_DESCRIPTION_LIST_SUCCESS";
export const LOAD_MASTER_JOB_DESCRIPTION_LIST_ERROR = "LOAD_MASTER_JOB_DESCRIPTION_LIST_ERROR";

export const ADDEDIT_MASTER_JOB_DESCRIPTION = "ADDEDIT_MASTER_JOB_DESCRIPTION";
export const SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION = "SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION";
export const SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION_SUCCESS =
  "SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION_SUCCESS";
export const SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION_ERROR =
  "SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION_ERROR";
export const CLOSE_ADDEDIT_MASTER_JOB_DESCRIPTION = "CLOSE_ADDEDIT_MASTER_JOB_DESCRIPTION";

export const DELETE_MASTER_JOB_DESCRIPTION = "DELETE_MASTER_JOB_DESCRIPTION";
export const DELETE_MASTER_JOB_DESCRIPTION_SUCCESS = "DELETE_MASTER_JOB_DESCRIPTION_SUCCESS";
export const DELETE_MASTER_JOB_DESCRIPTION_ERROR = "DELETE_MASTER_JOB_DESCRIPTION_ERROR";

export const loadMasterJobDescriptionList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_JOB_DESCRIPTION_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterJobDescriptionListSuccess = (data) => ({
  type: LOAD_MASTER_JOB_DESCRIPTION_LIST_SUCCESS,
  payload: data,
});

export const loadMasterJobDescriptionListError = (error) => ({
  type: LOAD_MASTER_JOB_DESCRIPTION_LIST_ERROR,
  payload: error,
});

export const addeditMasterJobDescription = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_JOB_DESCRIPTION,
  payload: {
    item,
    mode,
  },
});

export const closeAddeditMasterJobDescription = () => ({
  type: CLOSE_ADDEDIT_MASTER_JOB_DESCRIPTION,
});

export const saveAddeditMasterJobDescription = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterJobDescriptionSuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION_SUCCESS,
  payload: data,
});

export const saveAddeditMasterJobDescriptionError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION_ERROR,
  payload: error,
});

export const deleteMasterJobDescription = (data, confirmed = false) => ({
  type: DELETE_MASTER_JOB_DESCRIPTION,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterJobDescriptionSuccess = () => ({
  type: DELETE_MASTER_JOB_DESCRIPTION_SUCCESS,
});

export const deleteMasterJobDescriptionError = (error) => ({
  type: DELETE_MASTER_JOB_DESCRIPTION_ERROR,
  payload: error,
});
