import { INIT_PAGE_STATE } from "../../constants/constants";

// BIND DATA LIST COMPANY
export const GET_MASTER_COMPANY_LIST = "GET_MASTER_COMPANY_LIST";
export const GET_MASTER_COMPANY_LIST_SUCCESS =
  "GET_MASTER_COMPANY_LIST_SUCCESS";
export const GET_MASTER_COMPANY_LIST_ERROR = "GET_MASTER_COMPANY_LIST_ERROR";

// BIND DATA LIST CLIENT
export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_ERROR = "GET_CLIENT_LIST_ERROR";

// CHECK CLIENT HAS BERKAS
export const CHECK_BERKAS = "CHECK_BERKAS";
export const CHECK_BERKAS_SUCCESS = "CHECK_BERKAS_SUCCESS";
export const CHECK_BERKAS_ERROR = "CHECK_BERKAS_ERROR";

// GET BERKAS CLIENT
export const GET_BERKAS_CLIENT_LIST = "GET_BERKAS_CLIENT_LIST";
export const GET_BERKAS_CLIENT_LIST_SUCCESS = "GET_BERKAS_CLIENT_LIST_SUCCESS";
export const GET_BERKAS_CLIENT_LIST_ERROR = "GET_BERKAS_CLIENT_LIST_ERROR";

// GET DOCUMENT CLIENT
export const GET_DOCUMENTS_BERKAS_CLIENT_LIST =
  "GET_DOCUMENTS_BERKAS_CLIENT_LIST";
export const GET_DOCUMENTS_BERKAS_CLIENT_LIST_SUCCESS =
  "GET_DOCUMENTS_BERKAS_CLIENT_LIST_SUCCESS";
export const GET_DOCUMENTS_BERKAS_CLIENT_LIST_ERROR =
  "GET_DOCUMENTS_BERKAS_CLIENT_LIST_ERROR";

export const getCompanyList = () => ({
  type: GET_MASTER_COMPANY_LIST,
});

export const getCompanyListSuccess = (data) => ({
  type: GET_MASTER_COMPANY_LIST_SUCCESS,
  payload: data,
});

export const getCompanyListError = (error) => ({
  type: GET_MASTER_COMPANY_LIST_SUCCESS,
  payload: error,
});

export const getClientList = (p) => {
  const param = {
    ...{
      id: null,
      year: null,
      search: null,
    },
    ...p,
  };
  return {
    type: GET_CLIENT_LIST,
    payload: {
      id: param.id || p.idCompany,
      year: param.year,
      search: param.search,
    },
  };
};

export const getClientListSuccess = (data) => ({
  type: GET_CLIENT_LIST_SUCCESS,
  payload: data,
});

export const getClientListError = (error) => ({
  type: GET_CLIENT_LIST_SUCCESS,
  payload: error,
});

export const checkBerkasList = (client_id) => ({
  type: CHECK_BERKAS,
  payload: { client_id },
});

export const checkBerkasListSuccess = (data) => ({
  type: CHECK_BERKAS_SUCCESS,
  payload: data,
});

export const checkBerkasListError = (error) => ({
  type: CHECK_BERKAS_ERROR,
  payload: error,
});

export const getBerkasClientList = (client_id, name) => ({
  type: GET_BERKAS_CLIENT_LIST,
  payload: { client_id, name },
});

export const getBerkasClientListSuccess = (data) => ({
  type: GET_BERKAS_CLIENT_LIST_SUCCESS,
  payload: data,
});

export const getBerkasClientListError = (error) => ({
  type: GET_BERKAS_CLIENT_LIST_ERROR,
  payload: error,
});

export const getDocumentBerkasClientList = (client_id, name, docTypeName) => ({
  type: GET_DOCUMENTS_BERKAS_CLIENT_LIST,
  payload: { client_id, name, docTypeName },
});

export const getDocumentBerkasClientListSuccess = (data) => ({
  type: GET_DOCUMENTS_BERKAS_CLIENT_LIST_SUCCESS,
  payload: data,
});

export const getDocumentBerkasClientListError = (error) => ({
  type: GET_DOCUMENTS_BERKAS_CLIENT_LIST_ERROR,
  payload: error,
});
