import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";
import * as action from "./action";

export const getListState = (state) => state.companyApp.list;

const loadMasterCompanyListAsync = async (query) => GET(`/company?${query}`);
const addMasterCompanyAsync = async (item) => POST(`/company`, item);
const editMasterCompanyAsync = async (id, item) => PUT(`/company/${id}`, item);
const deleteMasterCompanyAsync = async (id) => DELETE(`/company/${id}`);
const loadMasterCityListAsync = async () => GET(`/city`);
const loadMasterProvinceListAsync = async () => GET(`/province`);
const getMasterCountryListAsync = async () => GET(`/country/all`);

function* loadMasterCompanyList({ payload }) {
  try {
    const list = yield select(getListState);

    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterCompanyListAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterCompanyListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterCompanyListError(error));
  }
}

function* getMasterCityList() {
  try {
    let response = null;
    response = yield call(loadMasterCityListAsync);
    yield put(action.getCityListSuccess(response));
  } catch (error) {
    yield put(action.getCityListError(error));
  }
}

function* loadMasterProvinceList() {
  try {
    const response = yield call(loadMasterProvinceListAsync);
    yield put(action.getProvinceListSuccess(response));
  } catch (error) {
    yield put(action.getProvinceListError(error));
  }
}

function* getMasterCountryList() {
  try {
    let response = null;
    response = yield call(getMasterCountryListAsync);
    yield put(action.getCountryListSuccess(response));
  } catch (error) {
    yield put(action.getCountryListError(error));
  }
}

function* saveAddeditMasterCompany({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterCompanyAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterCompanyAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterCompanySuccess(response));
      yield put(action.loadMasterCompanyList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterCompanyError(error));
  }
}

function* deleteMasterCompany({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterCompanyAsync, payload.item.id);
        yield put(action.deleteMasterCompanySuccess());
        yield put(action.loadMasterCompanyList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterCompanyError(error));
  }
}

export function* watchLoadMasterCompanyList() {
  yield takeEvery(action.LOAD_MASTER_COMPANY_LIST, loadMasterCompanyList);
}

export function* watchLoadMasterProvinceList() {
  yield takeEvery(action.ADDEDIT_MASTER_COMPANY, loadMasterProvinceList);
}

export function* watchSaveAddeditMasterCompany() {
  yield takeEvery(action.SAVE_ADDEDIT_MASTER_COMPANY, saveAddeditMasterCompany);
}

export function* watchDeleteMasterCompany() {
  yield takeEvery(action.DELETE_MASTER_COMPANY, deleteMasterCompany);
}

export function* watchGetCountryList() {
  yield takeEvery(action.GET_MASTER_COUNTRY_LIST, getMasterCountryList);
}

export function* watchGetCityList() {
  yield takeEvery(action.GET_MASTER_CITY_LIST, getMasterCityList);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterCompanyList),
    fork(watchLoadMasterProvinceList),
    fork(watchSaveAddeditMasterCompany),
    fork(watchDeleteMasterCompany),
    fork(watchGetCountryList),
    fork(watchGetCityList),
  ]);
}
