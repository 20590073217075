import { INIT_PAGE_STATE } from "../../../constants/constants";

import {
  LOAD_MASTER_DOCUMENT_LIST,
  LOAD_MASTER_DOCUMENT_LIST_ERROR,
  LOAD_MASTER_DOCUMENT_LIST_SUCCESS,
  ADDEDIT_MASTER_DOCUMENT,
  ADDEDIT_MASTER_FORM_FIELD_DOCUMENT,
  CLOSE_ADDEDIT_MASTER_DOCUMENT,
  CLOSE_ADDEDIT_MASTER_FORM_FIELD_DOCUMENT,
  DELETE_MASTER_DOCUMENT,
  DELETE_MASTER_DOCUMENT_ERROR,
  DELETE_MASTER_DOCUMENT_SUCCESS,
  SAVE_ADDEDIT_MASTER_DOCUMENT,
  SAVE_ADDEDIT_MASTER_DOCUMENT_ERROR,
  SAVE_ADDEDIT_MASTER_DOCUMENT_SUCCESS,
} from "./action";

const INIT_STATE = {
  list: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    orderBy: null,
    sortedBy: "asc",
    filterFields: "id:ilike;name:ilike",
    searchJoin: "or",
    page: INIT_PAGE_STATE,
    message: null,
  },
  addEdit: {
    data: null,
    mode: "add",
    loading: false,
    error: false,
    message: null,
  },

  addEditFormField: {
    data: null,
    mode: "add",
    loading: false,
    error: false,
    message: null,
  },

  deleting: {
    data: null,
    loading: false,
    error: false,
    message: null,
    confirmed: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_MASTER_DOCUMENT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: true,
          error: false,
          filter: action.payload.filter,
          orderBy: action.payload.orderBy,
          sortedBy: action.payload.sortedBy,
          page: INIT_PAGE_STATE,
          message: null,
        },
      };

    case LOAD_MASTER_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          page: {
            ...state.list.page,
            currentPage: action.payload.current_page,
            selectedPageSize: action.payload.per_page,
            totalItemCount: action.payload.total,
            totalPage: action.payload.last_page,
            itemCount: action.payload.total,
          },
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_MASTER_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case ADDEDIT_MASTER_DOCUMENT:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          mode: action.payload.mode,
          loading: false,
          error: false,
          message: null,
        },
      };

    case ADDEDIT_MASTER_FORM_FIELD_DOCUMENT:
      return {
        ...state,
        addEditFormField: {
          ...state.addEditFormField,
          data: action.payload.item,
          mode: action.payload.mode,
          loading: false,
          error: false,
          message: null,
        },
      };

    case SAVE_ADDEDIT_MASTER_DOCUMENT:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          loading: true,
          error: false,
          message: null,
        },
      };

    case SAVE_ADDEDIT_MASTER_DOCUMENT_SUCCESS:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          loading: false,
          error: true,
        },
      };

    case SAVE_ADDEDIT_MASTER_DOCUMENT_ERROR:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          loading: false,
          error: true,
          message: action.payload.toString(),
        },
      };

    case CLOSE_ADDEDIT_MASTER_DOCUMENT:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          mode: "add",
          loading: false,
          error: false,
          message: null,
        },
      };

    case CLOSE_ADDEDIT_MASTER_FORM_FIELD_DOCUMENT:
      return {
        ...state,
        addEditFormField: {
          ...state.addEditFormField,
          data: null,
          mode: "add",
          loading: false,
          error: false,
          message: null,
        },
      };

    case DELETE_MASTER_DOCUMENT:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: action.payload.item,
          confirmed: action.payload.confirmed,
          loading: action.payload.confirmed,
          error: false,
          message: null,
        },
      };

    case DELETE_MASTER_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: null,
          loading: false,
          error: false,
          message: null,
          confirmed: false,
        },
      };

    case DELETE_MASTER_DOCUMENT_ERROR:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          loading: false,
          error: true,
          message: action.payload,
          confirmed: false,
        },
      };

    default:
      return state;
  }
};
