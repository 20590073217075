import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_COMPANY_LIST = "LOAD_MASTER_COMPANY_LIST";
export const LOAD_MASTER_COMPANY_LIST_SUCCESS =
  "LOAD_MASTER_COMPANY_LIST_SUCCESS";
export const LOAD_MASTER_COMPANY_LIST_ERROR = "LOAD_MASTER_COMPANY_LIST_ERROR";

export const ADDEDIT_MASTER_COMPANY = "ADDEDIT_MASTER_COMPANY";
export const ADDEDIT_MASTER_COMPANY_SUCCESS = "ADDEDIT_MASTER_COMPANY_SUCCESS";
export const ADDEDIT_MASTER_COMPANY_ERROR = "ADDEDIT_MASTER_COMPANY_ERROR";
export const SAVE_ADDEDIT_MASTER_COMPANY = "SAVE_ADDEDIT_MASTER_COMPANY";
export const SAVE_ADDEDIT_MASTER_COMPANY_SUCCESS =
  "SAVE_ADDEDIT_MASTER_COMPANY_SUCCESS";
export const SAVE_ADDEDIT_MASTER_COMPANY_ERROR =
  "SAVE_ADDEDIT_MASTER_COMPANY_ERROR";
export const CLOSE_ADDEDIT_MASTER_COMPANY = "CLOSE_ADDEDIT_MASTER_COMPANY";

export const DELETE_MASTER_COMPANY = "DELETE_MASTER_COMPANY";
export const DELETE_MASTER_COMPANY_SUCCESS = "DELETE_MASTER_COMPANY_SUCCESS";
export const DELETE_MASTER_COMPANY_ERROR = "DELETE_MASTER_COMPANY_ERROR";

// BIND DATA LIST CITY
export const GET_MASTER_CITY_LIST = "GET_MASTER_CITY_LIST";
export const GET_MASTER_CITY_LIST_SUCCESS = "GET_MASTER_CITY_LIST_SUCCESS";
export const GET_MASTER_CITY_LIST_ERROR = "GET_MASTER_CITY_LIST_ERROR";

// BIND DATA LIST PROVINCE
export const GET_MASTER_PROVINCE_LIST = "GET_MASTER_PROVINCE_LIST";
export const GET_MASTER_PROVINCE_LIST_SUCCESS =
  "GET_MASTER_PROVINCE_LIST_SUCCESS";
export const GET_MASTER_PROVINCE_LIST_ERROR = "GET_MASTER_PROVINCE_LIST_ERROR";

// BIND DATA LIST COUNTRY
export const GET_MASTER_COUNTRY_LIST = "GET_MASTER_COUNTRY_LIST";
export const GET_MASTER_COUNTRY_LIST_SUCCESS =
  "GET_MASTER_COUNTRY_LIST_SUCCESS";
export const GET_MASTER_COUNTRY_LIST_ERROR = "GET_MASTER_COUNTRY_LIST_ERROR";

export const loadMasterCompanyList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_COMPANY_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterCompanyListSuccess = (data) => ({
  type: LOAD_MASTER_COMPANY_LIST_SUCCESS,
  payload: data,
});

export const loadMasterCompanyListError = (error) => ({
  type: LOAD_MASTER_COMPANY_LIST_ERROR,
  payload: error,
});

export const addeditMasterCompany = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_COMPANY,
  payload: {
    item,
    mode,
  },
});

export const addEditMasterCompanySuccess = (data) => ({
  type: ADDEDIT_MASTER_COMPANY_SUCCESS,
  payload: data,
});

export const addEditMasterCompanyError = (error) => ({
  type: ADDEDIT_MASTER_COMPANY_ERROR,
  payload: error,
});

export const closeAddeditMasterCompany = () => ({
  type: CLOSE_ADDEDIT_MASTER_COMPANY,
});

export const saveAddeditMasterCompany = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_COMPANY,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterCompanySuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_COMPANY_SUCCESS,
  payload: data,
});

export const saveAddeditMasterCompanyError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_COMPANY_ERROR,
  payload: error,
});

export const deleteMasterCompany = (data, confirmed = false) => ({
  type: DELETE_MASTER_COMPANY,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterCompanySuccess = () => ({
  type: DELETE_MASTER_COMPANY_SUCCESS,
});

export const deleteMasterCompanyError = (error) => ({
  type: DELETE_MASTER_COMPANY_ERROR,
  payload: error,
});

// BIND CITY
export const getCityList = () => ({
  type: GET_MASTER_CITY_LIST,
});

export const getCityListSuccess = (data) => ({
  type: GET_MASTER_CITY_LIST_SUCCESS,
  payload: data,
});

export const getCityListError = (error) => ({
  type: GET_MASTER_CITY_LIST_ERROR,
  payload: error,
});

// BIND PROVINCE
export const getProvinceList = () => ({
  type: GET_MASTER_PROVINCE_LIST,
});

export const getProvinceListSuccess = (data) => ({
  type: GET_MASTER_PROVINCE_LIST_SUCCESS,
  payload: data,
});

export const getProvinceListError = (error) => ({
  type: GET_MASTER_PROVINCE_LIST_ERROR,
  payload: error,
});

// BIND COUNTRY
export const getCountryList = () => ({
  type: GET_MASTER_COUNTRY_LIST,
});

export const getCountryListSuccess = (data) => ({
  type: GET_MASTER_COUNTRY_LIST_SUCCESS,
  payload: data,
});

export const getCountryListError = (error) => ({
  type: GET_MASTER_COUNTRY_LIST_ERROR,
  payload: error,
});
