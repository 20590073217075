import React from "react";

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("gogo_current_user", JSON.stringify(user));
    } else {
      localStorage.removeItem("gogo_current_user");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

export const getCurrentToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem("JWT-TOKEN") != null
        ? JSON.parse(localStorage.getItem("JWT-TOKEN"))
        : null;
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js  : getCurrentToken -> error",
      error
    );
    token = null;
  }
  return token;
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("gogo_current_user") != null
        ? JSON.parse(localStorage.getItem("gogo_current_user"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    user = null;
  }
  return user;
};

export const setCurrentToken = (token) => {
  try {
    if (token) {
      localStorage.setItem("JWT-TOKEN", JSON.stringify(token));
    } else {
      localStorage.removeItem("JWT-TOKEN");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentToken -> error", error);
  }
};

export const RenderHTML = ({ HTML }) => (
  // eslint-disable-next-line react/no-danger
  <span className="text-break" dangerouslySetInnerHTML={{ __html: HTML }} />
);
