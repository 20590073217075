import React from "react";
import axios from "axios";
import { getCurrentToken, getCurrentUser } from "./Utils";

// HANDLE SUCCESS/ERROR
/* eslint-disable import/prefer-default-export */
export const handleApiErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const handleOkResponse = (response) => {
  if (
    response.headers.get("Content-Type") &&
    response.headers.get("Content-Type").indexOf("application/json") >= 0
  ) {
    return response.json();
  }

  return response.text();
};

// FOR AUTH
export const simplePOST = (contextPath, body = "{}") =>
  fetch(`${process.env.REACT_APP_BACKEND}${contextPath}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  })
    .then((response) => handleApiErrors(response))
    .then((response) => handleOkResponse(response));

export const GET = (url) =>
  axios({
    url,
    method: "GET",
  }).then((response) => response.data);

export const POST = (url, reqBody) =>
  axios({
    url,
    method: "POST",
    data: reqBody,
  }).then((response) => response.data);

export const PUT = (url, reqBody) =>
  axios({
    url,
    method: "PUT",
    data: reqBody,
  }).then((response) => response.data);

export const DELETE = (url) =>
  axios({
    url,
    method: "DELETE",
  }).then((response) => response.data);

export const previewFile = async (downloadUrl) => {
  await axios({
    url: `/${downloadUrl}`,
    method: "GET",
    responseType: "application/json",
  }).then((response) => {
    if (response.status === 200) {
      const byteCharacters = atob(response?.data?.data.blob);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: response?.data?.data.mime_type,
      });
      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location.href = url;
      const link = document.createElement("object");
      document.body.appendChild(link);
    } else {
      console.log("gagal preview");
    }
  });
  console.log("gagal");
};

export const Indexing = (url, reqBody) =>
  axios({
    url,
    method: "POST",
    data: reqBody,
  }).then((response) => response.message);
