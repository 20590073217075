import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";
import * as action from "./action";

export const getListState = (state) => state.taskListRejectApp.list;
export const getDetailState = (state) => state.taskListRejectApp.addEditDetail;

const loadListTaskAsync = async (query) => GET(`/transaction-task/indexReject?${query}`);
const loadListTaskDetailAsync = async (id) => GET(`/transaction-task-detail/${id}`);
const addListTaskAsync = async (item) => POST(`/transaction-task`, item);
const addListTaskDetailAsync = async (item) => PUT(`/transaction-task-detail`, item);
const editListTaskAsync = async (item) => PUT(`/transaction-task`, item);
const editListTaskDetailAsync = async (id) => DELETE(`/transaction-task-detail/${id}`);
const deleteListTaskAsync = async (id) => DELETE(`/transaction-task/${id}`);
const rejectTaskAsync = async (id) => POST(`/transaction-task/rejectTask/${id}`);

//TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
const loadMasterClientListAsync = async (query) => GET(`/client?${query}`);
const loadMasterTaskAsync = async (query) => GET(`/task?${query}`);
const loadMasterJobDescriptionAsync = async (query) =>
  GET(`/job-description?${query}`);

function* loadListTask({ payload }) {
  try {
    const list = yield select(getListState);

    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadListTaskAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadListTaskSuccess(response));
  } catch (error) {
    yield put(action.loadListTaskError(error));
  }
}

function* loadListTaskDetail() {
  try {
    const detail = yield select(getDetailState);
    const response = yield call(loadListTaskDetailAsync, detail.data?.id);
    yield put(action.loadListTaskDetailSuccess(response));
  } catch (error) {
    yield put(action.loadListTaskDetailError(error));
  }
}

function* rejectTask({payload}) {
  try {
    const response = yield call(rejectTaskAsync,payload.id);
    yield put(action.loadListTask());
    SuccessUpdate();
  } catch (error) {
    yield put(action.loadListTask());
  }
}

function* saveAddeditListTask({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editListTaskAsync,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addListTaskAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditListTaskSuccess(response));
      yield put(action.loadListTask());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditListTaskError(error));
  }
}

function* saveAddeditListTaskDetail({ payload }) {
  console.log('Payload', payload);
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editListTaskDetailAsync,
          payload.item.task_detail_id
        );
      } else if (payload.mode === "add") {
        response = yield call(addListTaskDetailAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditListTaskDetailSuccess(response));
      yield put(action.loadListTaskDetail());
      yield put(action.loadListTask());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditListTaskDetailError(error));
  }
}

function* deleteListTask({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteListTaskAsync, payload.item.id);
        yield put(action.deleteListTaskSuccess());
        yield put(action.loadListTask());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteListTaskError(error));
  }
}

//TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
function* loadMasterClientList({ payload }) {
  try {
    const list = yield select(getListState);

    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: "id",
      },
      {
        name: "sortedBy",
        value: "asc",
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];
    
    const response = yield call(
      loadMasterClientListAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterClientListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterClientListError(error));
  }
}

function* loadMasterJobDescriptionList({ payload }) {
  try {
    const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: "id",
      },
      {
        name: "sortedBy",
        value: "asc",
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    const response = yield call(
      loadMasterJobDescriptionAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterJobDescriptionListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterJobDescriptionListError(error));
  }
}

function* loadMasterTaskList({ payload }) {
  try {
    // const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: "id",
      },
      {
        name: "sortedBy",
        value: "asc",
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    const response = yield call(
      loadMasterTaskAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterTaskListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterTaskListError(error));
  }
}

export function* watchLoadListTaskEReject() {
  yield takeEvery(action.LOAD_LIST_TASK_REJECT, loadListTask);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadListTaskEReject),
  ]);
}
