/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";
import * as action from "./action";

export const getListState = (state) => state.jobDescApp.list;

const loadMasterJobDescriptionAsync = async (query) =>
  GET(`/job-description?${query}`);

const addMasterJobDescriptionAsync = async (item) =>
  POST(`/job-description`, item);
const editMasterJobDescriptionAsync = async (id, item) =>
  PUT(`/job-description/${id}`, item);
const deleteMasterJobDescriptionAsync = async (id) =>
  DELETE(`/job-description/${id}`);

function* loadMasterJobDescriptionList({ payload }) {
  try {
    const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterJobDescriptionAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterJobDescriptionListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterJobDescriptionListError(error));
  }
}

function* saveAddeditMasterJobDescription({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterJobDescriptionAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterJobDescriptionAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterJobDescriptionSuccess(response));
      yield put(action.loadMasterJobDescriptionList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterJobDescriptionError(error));
  }
}

function* deleteMasterJobDescription({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterJobDescriptionAsync, payload.item.id);
        yield put(action.deleteMasterJobDescriptionSuccess());
        yield put(action.loadMasterJobDescriptionList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterJobDescriptionError(error));
  }
}

export function* watchLoadMasterJobDescriptionList() {
  yield takeEvery(
    action.LOAD_MASTER_JOB_DESCRIPTION_LIST,
    loadMasterJobDescriptionList
  );
}

export function* watchSaveAddeditMasterJobDescription() {
  yield takeEvery(
    action.SAVE_ADDEDIT_MASTER_JOB_DESCRIPTION,
    saveAddeditMasterJobDescription
  );
}

export function* watchDeleteMasterJobDescription() {
  yield takeEvery(
    action.DELETE_MASTER_JOB_DESCRIPTION,
    deleteMasterJobDescription
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterJobDescriptionList),
    fork(watchSaveAddeditMasterJobDescription),
    fork(watchDeleteMasterJobDescription),
  ]);
}
