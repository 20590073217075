import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";
import * as action from "./action";

export const getListState = (state) => state.taskListApp.list;
export const getListStateJob = (state) => state.taskListApp.listJob;
export const getDetailState = (state) => state.taskListApp.addEditDetail;

const loadListTaskAsync = async (query) => GET(`/transaction-task?${query}`);
const loadListTaskDetailAsync = async (id) =>
  GET(`/transaction-task-detail/${id}`);
const addListTaskAsync = async (item) => POST(`/transaction-task`, item);
const addListTaskDetailAsync = async (item) =>
  PUT(`/transaction-task-detail`, item);
const approveListTaskDetailAsync = async (id) =>
  PUT(`/transaction-task-detail/approve/${id}`);
const editListTaskAsync = async (item) => PUT(`/transaction-task`, item);
const editListTaskDetailAsync = async (id) =>
  DELETE(`/transaction-task-detail/${id}`);
const deleteListTaskAsync = async (id) => DELETE(`/transaction-task/${id}`);
const rejectTaskAsync = async (id) =>
  POST(`/transaction-task/rejectTask/${id}`);
const approveTaskAsync = async (item) =>
  POST(`/transaction-task/approveTask`, item);

//TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
const loadMasterClientListAsync = async (query) => GET(`/client/task?${query}`);
const loadMasterTaskAsync = async (query) => GET(`/task/all?${query}`);
const loadMasterJobDescriptionAsync = async (query) =>
  GET(`/job-description/task?${query}`);

function* loadListTask({ payload }) {
  try {
    const list = yield select(getListState);

    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadListTaskAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadListTaskSuccess(response));
  } catch (error) {
    yield put(action.loadListTaskError(error));
  }
}

function* loadListTaskDetail() {
  try {
    const detail = yield select(getDetailState);
    const response = yield call(loadListTaskDetailAsync, detail.data?.id);
    yield put(action.loadListTaskDetailSuccess(response));
  } catch (error) {
    yield put(action.loadListTaskDetailError(error));
  }
}

function* rejectTask({ payload }) {
  try {
    const response = yield call(rejectTaskAsync, payload.id);
    yield put(action.loadListTask());
    SuccessUpdate();
  } catch (error) {
    yield put(action.loadListTask());
  }
}

function* approveTask({ payload }) {
  try {
    const response = yield call(approveTaskAsync, payload);
    yield put(action.addApproveTaskSuccess(response));
    yield put(action.loadListTask());
    SuccessUpdate();
  } catch (error) {
    yield put(action.addApproveTaskError());
  }
}

function* saveAddeditListTask({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(editListTaskAsync, payload.item);
      } else if (payload.mode === "add") {
        response = yield call(addListTaskAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditListTaskSuccess(response));
      yield put(action.loadListTask());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditListTaskError(error));
  }
}

function* saveAddeditListTaskDetail({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editListTaskDetailAsync,
          payload.item.task_detail_id
        );
      } else if (payload.mode === "add") {
        response = yield call(addListTaskDetailAsync, payload.item);
      } else if (payload.mode === "approve") {
        response = yield call(
          approveListTaskDetailAsync,
          payload.item.task_detail_id
        );
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditListTaskDetailSuccess(response));
      yield put(action.loadListTaskDetail());
      yield put(action.loadListTask());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditListTaskDetailError(error));
  }
}

function* deleteListTask({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteListTaskAsync, payload.item.id);
        yield put(action.deleteListTaskSuccess());
        yield put(action.loadListTask());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteListTaskError(error));
  }
}

//TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
function* loadMasterClientList({ payload }) {
  try {
    const list = yield select(getListState);

    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: "id",
      },
      {
        name: "sortedBy",
        value: "asc",
      },
      {
        name: "limit",
        // value: payload.limit,
        value: 0,
      },
    ];

    const response = yield call(
      loadMasterClientListAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterClientListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterClientListError(error));
  }
}

function* loadMasterJobDescriptionList({ payload }) {
  try {
    const list = yield select(getListStateJob);
    console.log('listDisini', list);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: "id",
      },
      {
        name: "sortedBy",
        value: "asc",
      },
      {
        name: "limit",
        // value: payload.limit,
        value: 0,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }

    const response = yield call(
      loadMasterJobDescriptionAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterJobDescriptionListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterJobDescriptionListError(error));
  }
}

function* loadMasterTaskList({ payload }) {
  try {
    // const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: "id",
      },
      {
        name: "sortedBy",
        value: "asc",
      },
      {
        name: "limit",
        // value: payload.limit,
        value: 0,
      },
    ];

    const response = yield call(
      loadMasterTaskAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterTaskListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterTaskListError(error));
  }
}

export function* watchLoadListTask() {
  yield takeEvery(action.LOAD_LIST_TASK, loadListTask);
}

export function* watchLoadListTaskDetail() {
  yield takeEvery(action.LOAD_LIST_TASK_DETAIL, loadListTaskDetail);
}

export function* watchSaveAddeditListTask() {
  yield takeEvery(action.SAVE_ADDEDIT_LIST_TASK, saveAddeditListTask);
}

export function* watchLoadMasterClientListDetail() {
  yield takeEvery(
    action.SAVE_ADDEDIT_LIST_TASK_DETAIL,
    saveAddeditListTaskDetail
  );
}

export function* watchDeleteListTask() {
  yield takeEvery(action.DELETE_LIST_TASK, deleteListTask);
}

export function* watchLoadMasterClientList() {
  yield takeEvery(action.LOAD_MASTER_CLIENT_LIST_TASK, loadMasterClientList);
}

export function* watchLoadMasterJobDescriptionList() {
  yield takeEvery(
    action.LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK,
    loadMasterJobDescriptionList
  );
}

export function* watchLoadMasterTaskList() {
  yield takeEvery(action.LOAD_MASTER_TASK, loadMasterTaskList);
}

export function* watchRejectTask() {
  yield takeEvery(action.REJECT_TASK, rejectTask);
}

export function* watchApproveTask() {
  yield takeEvery(action.ADD_APPROVE_TASK, approveTask);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadListTask),
    fork(watchLoadListTaskDetail),
    fork(watchSaveAddeditListTask),
    fork(watchDeleteListTask),
    fork(watchLoadMasterClientList),
    fork(watchLoadMasterJobDescriptionList),
    fork(watchLoadMasterTaskList),
    fork(watchLoadMasterClientListDetail),
    fork(watchRejectTask),
    fork(watchApproveTask),
  ]);
}
